* {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.dark {
  background-color: #333;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #333;
}

.logo-img {
  text-align: center;
}

.navbar {
  text-align: center;
  margin: 2rem;
}

.nav-links {
  text-decoration: none;
  margin: 1.5rem;
  font-size: 18px;
  color: black;
}

.mobilenav {
  display: none;
}

.categories {
  display: flex;
  flex-wrap: wrap;
}

.icon-box {
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin: 2.5rem;
  border: solid 1px;
  width: 100px;
  height: 125px;
  border-radius: 25px;
}

.icon-img {
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin: 1.5rem;
}

.icon-img img {
  width: 36px;
  height: 36px;
}

.btn-global {
  background-color: unset;
  border: unset;
}

.btn-global:hover {
  color: aqua;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .navbar {
    display: none;
  }
  h2 {
    text-align: center;
  }
  .mobilenav {
    display: block;
    text-align: center;
  }
  .mobilenav button {
    background-color: unset;
    border: unset;
  }
  #mobilenav-links {
    display: none;
  }

  .mobilenav-menu {
    display: flex;
    text-align: center;
  }
  .mobilenav-menu li {
    list-style: none;
  }
  .categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
  }
  .icon-box {
    margin: 2rem;
    width: 200px;
    height: 225px;
  }
  .icon-img img {
    width: 72px;
    height: 72px;
  }
  .icon-btn button {
    font-size: 24px;
    padding: 1.5rem;
  }
}

#homeHeader {
  padding: 2rem;
}
.slider-wrapper {
  position: relative;
  max-width: 48rem;
  margin: 0 auto;
}
.slider {
  display: flex;
  aspect-ratio: 16 / 9;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  box-shadow: 0 1.5rem 3rem -0.075rem hsla(0, 0%, 0%, 0.25);
  border-radius: 0.5rem;
}

.slider img {
  flex: 1 0 100%;
  scroll-snap-align: start;
  object-fit: cover;
}
.slider-nav {
  display: flex;
  column-gap: 1rem;
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  translate: translateX(-50%);
  z-index: 1;
}
.slider-nav a {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  opacity: 75%;
  transition: opacity ease 250ms;
}
.slider-nav a:hover {
  opacity: 1;
}
