/*KodeChoice CSS Template*/
/*Please read the "README.md"*/
/*Global KodeChoice CSS*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html {
  background-color: white;
}
.kc-hidden {
  display: none;
}
.kc-scroll-smooth {
  scroll-behavior: smooth;
}
.kc-clearfix {
  overflow: auto;
}
.kc-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.kc-center-div {
  justify-content: center;
  justify-items: center;
  align-items: center;
}
/*KodeChoice Navigation Bar*/
.kc-side-bar {
  grid-area: s;
}
/*KodeChoice List*/
.kc-list-none {
  list-style: none;
}
/*KodeChoice Margin Padding*/
.kc-padding-s {
  padding: 1.5rem;
}
.kc-padding-m {
  padding: 2rem;
}
.kc-padding-l {
  padding: 2.5rem;
}
.kc-margin-s {
  margin: 1.5rem;
}
.kc-margin-m {
  margin: 2rem;
}
.kc-margin-l {
  margin: 2.5rem;
}
/*KodeChoice Cards*/
.kc-card-m {
  width: fit-content;
  height: auto;
  border: 1px solid black;
}
.kc-card-img {
  width: 300px;
  height: 400px;
}
/*KodeChoice Shadows*/
.kc-shadow-s {
  box-shadow: 1px 1px 1px black;
}
/*KodeChoice Flex Container*/
.kc-flex-container {
  display: flex;
}
.kc-flex-wrap {
  flex-wrap: wrap;
}
.kc-flex-nowrap {
  flex-wrap: nowrap;
}
.kc-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
/*KodeChoice Container*/
.kc-container {
  width: min(90%, 968px);
  margin-inline: auto;
}
/*KodeChoice Center*/
.kc-text-center {
  text-align: center;
}
.kc-text-dec-none {
  text-decoration: none;
}
/*KodeChoice Default Color*/
.kc-cl-blue {
  color: blue !important;
}
.kc-cl-black {
  color: black;
}
/*KodeChoice Default Background Color*/
.kc-bg-black {
  background-color: black;
}
/*KodeChoice Colors STARTS*/
/*KodeChoice Blue Section*/
.kc-cl-bright-blue {
  color: #51e2f5 !important;
}
.kc-cl-blue-light {
  color: #00ddff !important;
}
.kc-cl-brutal-blue {
  color: #0049b7 !important;
}
.kc-cl-night-blue {
  color: #2d545e !important;
}
.kc-cl-night-blue-shadow {
  color: #12343b !important;
}
.kc-cl-blue-water {
  color: #39a0ca !important;
}
.kc-cl-mountain-shadow-blue {
  color: #101357 !important;
}
.kc-cl-bluebell-light-blue {
  color: #00a0a0 !important;
}
.kc-cl-lightning-blue {
  color: #51d0de !important;
}
.kc-cl-blue-popsicle {
  color: #0f2862 !important;
}
.kc-cl-blueberry {
  color: #6b7a8f !important;
}
.kc-cl-left-blue {
  color: #1561ad !important;
}
.kc-cl-right-blue-muted {
  color: #1c77ac !important;
}
.kc-cl-blue-green {
  color: #1dbab4 !important;
}
.kc-cl-blue-mountain {
  color: #09868b !important;
}
.kc-cl-light-blue-backdrop {
  color: #76c1d4 !important;
}
.kc-cl-lightsaber-blue {
  color: #c2dde6 !important;
}
.kc-cl-ironic-blues {
  color: #0e0fed !important;
}
.kc-cl-light-blue-green {
  color: #6ed3cf !important;
}
.kc-cl-french-laundry-blue {
  color: #3a4660 !important;
}
.kc-cl-lonely-blue {
  color: #005995 !important;
}
.kc-cl-heritage-blue {
  color: #313d4b !important;
}
.kc-cl-blue-beans {
  color: #7acfd6 !important;
}
.kc-cl-morning-sky-blue {
  color: #cae4db !important;
}
.kc-cl-cerulean {
  color: #00303f !important;
}
.kc-cl-mist {
  color: #7a9d96 !important;
}
.kc-cl-fresh {
  color: #4abdac !important;
}
.kc-cl-ol-trusty-blue {
  color: #368cbf !important;
}
.kc-cl-optimish-blue {
  color: #269ccc !important;
}
.kc-cl-blue-algae {
  color: #9ed2c5 !important;
}
.kc-cl-sky-blue {
  color: #8cbcd0 !important;
}
.kc-cl-dark-blue-purple {
  color: #111c30 !important;
}
.kc-cl-tiny-sweet-blue {
  color: #b5e9e9 !important;
}
.kc-cl-blue-green-mist {
  color: #daefd9 !important;
}
.kc-cl-dusty-white {
  color: #edf756 !important;
}
.kc-cl-pink-sand {
  color: #ffa8b6 !important;
}
.kc-cl-dark-sand {
  color: #a28089 !important;
}
.kc-cl-ice-cold {
  color: #a0d2eb !important;
}
.kc-cl-freeze-purple {
  color: #e5eaf5 !important;
}
.kc-cl-medium-purple {
  color: #d0bdf4 !important;
}
.kc-cl-purple-pain {
  color: #8458b3 !important;
}
.kc-cl-heavy-purple {
  color: #a28089 !important;
}
.kc-cl-queen-red {
  color: #ff1d58 !important;
}
.kc-cl-sister-pink {
  color: #f75990 !important;
}
.kc-cl-crown-yellow {
  color: #fff685 !important;
}
.kc-cl-brightly-orange {
  color: #ff1e00 !important;
}
.kc-cl-dilmy-blue {
  color: #e8f9fd !important;
}
.kc-cl-highlight-green {
  color: #59ce8f !important;
}
.kc-cl-bright-orange {
  color: #f43a09 !important;
}
.kc-cl-grandpa-orange {
  color: #ffb766 !important;
}
.kc-cl-grey-blue-green {
  color: #c2edda !important;
}
.kc-cl-live-green {
  color: #68d388 !important;
}
.kc-cl-pinky {
  color: #fbe3e8 !important;
}
.kc-cl-blue-greeny {
  color: #5cbdb9 !important;
}
.kc-cl-teeny-greeny {
  color: #ebf6f5 !important;
}
.kc-cl-bright-green {
  color: #beef00 !important;
}
.kc-cl-electric-red {
  color: #ff0028 !important;
}
.kc-cl-deep-green {
  color: #657a00 !important;
}
.kc-cl-power-blue {
  color: #1400c6 !important;
}
.kc-cl-tan {
  color: #fceed1 !important;
}
.kc-cl-purple-y {
  color: #7d3cff !important;
}
.kc-cl-yellow-gloves {
  color: #f2d53c !important;
}
.kc-cl-redhead {
  color: #c80e13 !important;
}
.kc-cl-sand-tan {
  color: #e1b382 !important;
}
.kc-cl-sand-tan-shadow {
  color: #c89666 !important;
}
.kc-cl-ragin-beige {
  color: #fff5d7 !important;
}
.kc-cl-coral-pink {
  color: #ff5e6c !important;
}
.kc-cl-sleuthe-yellow {
  color: #feb300 !important;
}
.kc-cl-pink-leaf {
  color: #ffaaab !important;
}
.kc-cl-grassy-green {
  color: #9bc400 !important;
}
.kc-cl-purple-mountain-majesty {
  color: #8076a3 !important;
}
.kc-cl-misty-mountain-pink {
  color: #f9c5bd !important;
}
.kc-cl-factory-stone-purple {
  color: #7c677f !important;
}
.kc-cl-green-treeline {
  color: #478550 !important;
}
.kc-cl-purple-baseline {
  color: #161748 !important;
}
.kc-cl-pink-highlight {
  color: #f95d9b !important;
}
.kc-cl-yellow-bg {
  color: #ffde22 !important;
}
.kc-cl-pink-red {
  color: #ff414e !important;
}
.kc-cl-orange-c {
  color: #ff8928 !important;
}
.kc-cl-white-layover {
  color: #ffffff !important;
}
.kc-cl-old-makeup-pink {
  color: #fea49f !important;
}
.kc-cl-goldenrod-yellow {
  color: #fbaf08 !important;
}
.kc-cl-bold-green {
  color: #007f4f !important;
}
.kc-cl-lightning-purple {
  color: #bf4aa8 !important;
}
.kc-cl-brain-wrinkle-white {
  color: #d9d9d9 !important;
}
.kc-cl-redline {
  color: #9e363a !important;
}
.kc-cl-purple-shadow {
  color: #091f36 !important;
}
.kc-cl-grey-blue-leaf {
  color: #4f5f76 !important;
}
.kc-cl-apricot {
  color: #f7882f !important;
}
.kc-cl-citrus {
  color: #f7c331 !important;
}
.kc-cl-apple-core {
  color: #dcc7aa !important;
}
.kc-cl-red-orange {
  color: #fc5226 !important;
}
.kc-cl-redder {
  color: #ff3a22 !important;
}
.kc-cl-goldi-lots {
  color: #c7af6b !important;
}
.kc-cl-darker-gold {
  color: #a4893d !important;
}
.kc-cl-silver-tongue {
  color: #628078 !important;
}
.kc-cl-barely-green {
  color: #acb7ae !important;
}
.kc-cl-brown-shirt {
  color: #82716e !important;
}
.kc-cl-tan-blonde {
  color: #e4decd !important;
}
.kc-cl-blondey {
  color: #c2b490 !important;
}
.kc-cl-green-mountain {
  color: #3d7c47 !important;
}
.kc-cl-barely-gray-edge {
  color: #f7f7f7 !important;
}
.kc-cl-grey-silver {
  color: #bccbde !important;
}
.kc-cl-purple-dark {
  color: #431c5d !important;
}
.kc-cl-orange-dark {
  color: #e05915 !important;
}
.kc-cl-yellowbrite {
  color: #cdd422 !important;
}
.kc-cl-paintful-red {
  color: #eb1736 !important;
}
.kc-cl-old-purple {
  color: #5252d4 !important;
}
.kc-cl-lighter-purple {
  color: #7575dd;
}
.kc-cl-shadow-purple {
  color: #781a44 !important;
}
.kc-cl-strange-green {
  color: #8bf0ba !important;
}
.kc-cl-blue-underling {
  color: #94f0f1 !important;
}
.kc-cl-pinky-ring {
  color: #f2b1d8 !important;
}
.kc-cl-egg-yellows {
  color: #ffdc6a !important;
}
.kc-cl-soft-purple {
  color: #9068be !important;
}
.kc-cl-eighties-grey {
  color: #e1e8f0 !important;
}
.kc-cl-rich-red {
  color: #e62739 !important;
}
.kc-cl-comfort-tan {
  color: #c9af98 !important;
}
.kc-cl-peachy-kreme {
  color: #ed8a63 !important;
}
.kc-cl-brown-bonnet {
  color: #845007 !important;
}
.kc-cl-ya-yellow {
  color: #feda6a !important;
}
.silver-fox {
  color: #d4d4dc !important;
}
.kc-cl-deep-matte-grey {
  color: #393f4d !important;
}
.kc-cl-dark-slate {
  color: #1d1e22 !important;
}
.kc-cl-simpler-lime-green {
  color: #7dce94 !important;
}
.kc-cl-scuffed-dark-grey {
  color: #3d3d3f !important;
}
.kc-cl-vanilla-grey {
  color: #f6f5f3 !important;
}
.kc-cl-white-ish {
  color: #f9f8fd !important;
}
.kc-cl-rose-gold {
  color: #bd8c7d !important;
}
.kc-cl-soft-gold {
  color: #d1bfa7 !important;
}
.kc-cl-silver {
  color: #8e8e90 !important;
}
.kc-cl-onyx {
  color: #49494b !important;
}
.kc-cl-yellow-hand {
  color: #fbf579 !important;
}
.kc-cl-stationary-pink-red {
  color: #fa625f !important;
}
.kc-cl-purpled {
  color: #600473 !important;
}
.kc-cl-red-overlaid {
  color: #cd5554 !important;
}
.kc-cl-photographed-brown {
  color: #91684a !important;
}
.kc-cl-algea-green {
  color: #00c07f !important;
}
.kc-cl-deep-red {
  color: #b11a21 !important;
}
.kc-cl-lighter-red {
  color: #e0474c !important;
}
.kc-cl-light-classy-grey {
  color: #f1f0ee !important;
}
.kc-cl-honey {
  color: #dcae1d !important;
}
.kc-cl-dark-grey {
  color: #393939 !important;
}
.kc-cl-deep-orange {
  color: #ff5a09 !important;
}
.kc-cl-yellow-orange {
  color: #be4f0c !important;
}
.kc-cl-vermillion {
  color: #fc4a1a !important;
}
.kc-cl-sunshine {
  color: #f7b733 !important;
}
.kc-cl-clean {
  color: #dfdce3 !important;
}
.kc-cl-accent-green {
  color: #7ebc59 !important;
}
.kc-cl-light-grey {
  color: #eaeaea !important;
}
/*12*/
/*KodeChoice Colors ENDS*/
/*KodeChoice Background Color Starts*/
.kc-bg-bright-blue {
  background-color: #51e2f5 !important;
}
.kc-bg-blue-light {
  background-color: #00ddff !important;
}
.kc-bg-brutal-blue {
  background-color: #0049b7 !important;
}
.kc-bg-night-blue {
  background-color: #2d545e !important;
}
.kc-bg-night-blue-shadow {
  background-color: #12343b !important;
}
.kc-bg-blue-water {
  background-color: #39a0ca !important;
}
.kc-bg-mountain-shadow-blue {
  background-color: #101357 !important;
}
.kc-bg-bluebell-light-blue {
  background-color: #00a0a0 !important;
}
.kc-bg-lightning-blue {
  background-color: #51d0de !important;
}
.kc-bg-blue-popsicle {
  background-color: #0f2862 !important;
}
.kc-bg-blueberry {
  background-color: #6b7a8f !important;
}
.kc-bg-left-blue {
  background-color: #1561ad !important;
}
.kc-bg-right-blue-muted {
  background-color: #1c77ac !important;
}
.kc-bg-blue-green {
  background-color: #1dbab4 !important;
}
.kc-bg-blue-mountain {
  background-color: #09868b !important;
}
.kc-bg-light-blue-backdrop {
  background-color: #76c1d4 !important;
}
.kc-bg-lightsaber-blue {
  background-color: #c2dde6 !important;
}
.kc-bg-ironic-blues {
  background-color: #0e0fed !important;
}
.kc-bg-light-blue-green {
  background-color: #6ed3cf !important;
}
.kc-bg-french-laundry-blue {
  background-color: #3a4660 !important;
}
.kc-bg-lonely-blue {
  background-color: #005995 !important;
}
.kc-bg-heritage-blue {
  background-color: #313d4b !important;
}
.kc-bg-blue-beans {
  background-color: #7acfd6 !important;
}
.kc-bg-morning-sky-blue {
  background-color: #cae4db !important;
}
.kc-bg-cerulean {
  background-color: #00303f !important;
}
.kc-bg-mist {
  background-color: #7a9d96 !important;
}
.kc-bg-fresh {
  background-color: #4abdac !important;
}
.kc-bg-ol-trusty-blue {
  background-color: #368cbf !important;
}
.kc-bg-optimish-blue {
  background-color: #269ccc !important;
}
.kc-bg-blue-algae {
  background-color: #9ed2c5 !important;
}
.kc-bg-sky-blue {
  background-color: #8cbcd0 !important;
}
.kc-bg-dark-blue-purple {
  background-color: #111c30 !important;
}
.kc-bg-tiny-sweet-blue {
  background-color: #b5e9e9 !important;
}
.kc-bg-blue-green-mist {
  background-color: #daefd9 !important;
}
.kc-bg-dusty-white {
  background-color: #edf756 !important;
}
.kc-bg-pink-sand {
  background-color: #ffa8b6 !important;
}
.kc-bg-dark-sand {
  background-color: #a28089 !important;
}
.kc-bg-ice-cold {
  background-color: #a0d2eb !important;
}
.kc-bg-freeze-purple {
  background-color: #e5eaf5 !important;
}
.kc-bg-medium-purple {
  background-color: #d0bdf4 !important;
}
.kc-bg-purple-pain {
  background-color: #8458b3 !important;
}
.kc-bg-heavy-purple {
  background-color: #a28089 !important;
}
.kc-bg-queen-red {
  background-color: #ff1d58 !important;
}
.kc-bg-sister-pink {
  background-color: #f75990 !important;
}
.kc-bg-crown-yellow {
  background-color: #fff685 !important;
}
.kc-bg-brightly-orange {
  background-color: #ff1e00 !important;
}
.kc-bg-dilmy-blue {
  background-color: #e8f9fd !important;
}
.kc-bg-highlight-green {
  background-color: #59ce8f !important;
}
.kc-bg-bright-orange {
  background-color: #f43a09 !important;
}
.kc-bg-grandpa-orange {
  background-color: #ffb766 !important;
}
.kc-bg-grey-blue-green {
  background-color: #c2edda !important;
}
.kc-bg-live-green {
  background-color: #68d388 !important;
}
.kc-bg-pinky {
  background-color: #fbe3e8 !important;
}
.kc-bg-blue-greeny {
  background-color: #5cbdb9 !important;
}
.kc-bg-teeny-greeny {
  background-color: #ebf6f5 !important;
}
.kc-bg-bright-green {
  background-color: #beef00 !important;
}
.kc-bg-electric-red {
  background-color: #ff0028 !important;
}
.kc-bg-deep-green {
  background-color: #657a00 !important;
}
.kc-bg-power-blue {
  background-color: #1400c6 !important;
}
.kc-bg-tan {
  background-color: #fceed1 !important;
}
.kc-bg-purple-y {
  background-color: #7d3cff !important;
}
.kc-bg-yellow-gloves {
  background-color: #f2d53c !important;
}
.kc-bg-redhead {
  background-color: #c80e13 !important;
}
.kc-bg-sand-tan {
  background-color: #e1b382 !important;
}
.kc-bg-sand-tan-shadow {
  background-color: #c89666 !important;
}
.kc-bg-ragin-beige {
  background-color: #fff5d7 !important;
}
.kc-bg-coral-pink {
  background-color: #ff5e6c !important;
}
.kc-bg-sleuthe-yellow {
  background-color: #feb300 !important;
}
.kc-bg-pink-leaf {
  background-color: #ffaaab !important;
}
.kc-bg-grassy-green {
  background-color: #9bc400 !important;
}
.kc-bg-purple-mountain-majesty {
  background-color: #8076a3 !important;
}
.kc-bg-misty-mountain-pink {
  background-color: #f9c5bd !important;
}
.kc-bg-factory-stone-purple {
  background-color: #7c677f !important;
}
.kc-bg-green-treeline {
  background-color: #478550 !important;
}
.kc-bg-purple-baseline {
  background-color: #161748 !important;
}
.kc-bg-pink-highlight {
  background-color: #f95d9b !important;
}
.kc-bg-yellow-bg {
  background-color: #ffde22 !important;
}
.kc-bg-pink-red {
  background-color: #ff414e !important;
}
.kc-bg-orange-c {
  background-color: #ff8928 !important;
}
.kc-bg-white-layover {
  background-color: #ffffff !important;
}
.kc-bg-old-makeup-pink {
  background-color: #fea49f !important;
}
.kc-bg-goldenrod-yellow {
  background-color: #fbaf08 !important;
}
.kc-bg-bold-green {
  background-color: #007f4f !important;
}
.kc-bg-lightning-purple {
  background-color: #bf4aa8 !important;
}
.kc-bg-brain-wrinkle-white {
  background-color: #d9d9d9 !important;
}
.kc-bg-redline {
  background-color: #9e363a !important;
}
.kc-bg-purple-shadow {
  background-color: #091f36 !important;
}
.kc-bg-grey-blue-leaf {
  background-color: #4f5f76 !important;
}
.kc-bg-apricot {
  background-color: #f7882f !important;
}
.kc-bg-citrus {
  background-color: #f7c331 !important;
}
.kc-bg-apple-core {
  background-color: #dcc7aa !important;
}
.kc-bg-red-orange {
  background-color: #fc5226 !important;
}
.kc-bg-redder {
  background-color: #ff3a22 !important;
}
.kc-bg-goldi-lots {
  background-color: #c7af6b !important;
}
.kc-bg-darker-gold {
  background-color: #a4893d !important;
}
.kc-bg-silver-tongue {
  background-color: #628078 !important;
}
.kc-bg-barely-green {
  background-color: #acb7ae !important;
}
.kc-bg-brown-shirt {
  background-color: #82716e !important;
}
.kc-bg-tan-blonde {
  background-color: #e4decd !important;
}
.kc-bg-blondey {
  background-color: #c2b490 !important;
}
.kc-bg-green-mountain {
  background-color: #3d7c47 !important;
}
.kc-bg-barely-gray-edge {
  background-color: #f7f7f7 !important;
}
.kc-bg-grey-silver {
  background-color: #bccbde !important;
}
.kc-bg-purple-dark {
  background-color: #431c5d !important;
}
.kc-bg-orange-dark {
  background-color: #e05915 !important;
}
.kc-bg-yellowbrite {
  background-color: #cdd422 !important;
}
.kc-bg-paintful-red {
  background-color: #eb1736 !important;
}
.kc-bg-old-purple {
  background-color: #5252d4 !important;
}
.kc-bg-lighter-purple {
  background-color: #7575dd;
}
.kc-bg-shadow-purple {
  background-color: #781a44 !important;
}
.kc-bg-strange-green {
  background-color: #8bf0ba !important;
}
.kc-bg-blue-underling {
  background-color: #94f0f1 !important;
}
.kc-bg-pinky-ring {
  background-color: #f2b1d8 !important;
}
.kc-bg-egg-yellows {
  background-color: #ffdc6a !important;
}
.kc-bg-soft-purple {
  background-color: #9068be !important;
}
.kc-bg-eighties-grey {
  background-color: #e1e8f0 !important;
}
.kc-bg-rich-red {
  background-color: #e62739 !important;
}
.kc-bg-comfort-tan {
  background-color: #c9af98 !important;
}
.kc-bg-peachy-kreme {
  background-color: #ed8a63 !important;
}
.kc-bg-brown-bonnet {
  background-color: #845007 !important;
}
.kc-bg-ya-yellow {
  background-color: #feda6a !important;
}
.silver-fox {
  background-color: #d4d4dc !important;
}
.kc-bg-deep-matte-grey {
  background-color: #393f4d !important;
}
.kc-bg-dark-slate {
  background-color: #1d1e22 !important;
}
.kc-bg-simpler-lime-green {
  background-color: #7dce94 !important;
}
.kc-bg-scuffed-dark-grey {
  background-color: #3d3d3f !important;
}
.kc-bg-vanilla-grey {
  background-color: #f6f5f3 !important;
}
.kc-bg-white-ish {
  background-color: #f9f8fd !important;
}
.kc-bg-rose-gold {
  background-color: #bd8c7d !important;
}
.kc-bg-soft-gold {
  background-color: #d1bfa7 !important;
}
.kc-bg-silver {
  background-color: #8e8e90 !important;
}
.kc-bg-onyx {
  background-color: #49494b !important;
}
.kc-bg-yellow-hand {
  background-color: #fbf579 !important;
}
.kc-bg-stationary-pink-red {
  background-color: #fa625f !important;
}
.kc-bg-purpled {
  background-color: #600473 !important;
}
.kc-bg-red-overlaid {
  background-color: #cd5554 !important;
}
.kc-bg-photographed-brown {
  background-color: #91684a !important;
}
.kc-bg-algea-green {
  background-color: #00c07f !important;
}
.kc-bg-deep-red {
  background-color: #b11a21 !important;
}
.kc-bg-lighter-red {
  background-color: #e0474c !important;
}
.kc-bg-light-classy-grey {
  background-color: #f1f0ee !important;
}
.kc-bg-honey {
  background-color: #dcae1d !important;
}
.kc-bg-dark-grey {
  background-color: #393939 !important;
}
.kc-bg-deep-orange {
  background-color: #ff5a09 !important;
}
.kc-bg-yellow-orange {
  background-color: #be4f0c !important;
}
.kc-bg-vermillion {
  background-color: #fc4a1a !important;
}
.kc-bg-sunshine {
  background-color: #f7b733 !important;
}
.kc-bg-clean {
  background-color: #dfdce3 !important;
}
.kc-bg-accent-green {
  background-color: #7ebc59 !important;
}
.kc-bg-light-grey {
  background-color: #eaeaea !important;
}
/*KodeChoice Background Color Ends*/
/*KodeChoice Position*/
.kc-position-absolute {
  position: absolute !important;
}
.kc-position-relative {
  position: relative !important;
}
.kc-position-fixed {
  position: fixed !important;
}
.kc-position-sticky {
  position: sticky !important;
}
/*KodeChoice Buttons*/
.kc-button-bg-unset {
  background-color: unset;
}
.kc-button-full {
  width: 100%;
  border: none;
}
/*KodeChoice Dropdown Menu*/
.kc-dropdown-btn {
  cursor: pointer;
}
.kc-dropdown {
  position: relative;
  display: inline-block;
}
.kc-dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}
.kc-dropdown-content-item {
  display: block;
}
.kc-dropdown:hover .kc-dropdown-content {
  display: block;
}
/*KodeChoice Round Corners*/
.kc-round-border-all-s {
  border-radius: 15px;
}
.kc-round-border-all-m {
  border-radius: 30px;
}
.kc-round-border-all-l {
  border-radius: 50px;
}
/*KodeChoice Left Round Corners*/
.kc-round-border-left-s {
  border-left: 15px;
}
.kc-round-border-left-m {
  border-left: 30px;
}
.kc-round-border-left-l {
  border-left: 50px;
}
/*KodeChoice Right Round Corners*/
.kc-round-border-right-s {
  border-right: 15px;
}
.kc-round-border-right-m {
  border-right: 30px;
}
.kc-round-border-right-l {
  border-right: 50px;
}
/*KodeChoice Top Round Corners*/
.kc-round-border-top-s {
  border-top: 15px;
}
.kc-round-border-top-m {
  border-top: 30px;
}
.kc-round-border-top-l {
  border-top: 50px;
}
/*KodeChoice Bottom Round Corners*/
.kc-round-border-bottom-s {
  border-bottom: 15px;
}
.kc-round-border-bottom-m {
  border-bottom: 30px;
}
.kc-round-border-bottom-l {
  border-bottom: 50px;
}
/*KodeChoice Responsive Image*/
.kc-responsive-img {
  width: 100%;
  height: auto;
}
.kc-round-img {
  border-radius: 50%;
}
.kc-avatar-img {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.kc-center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/*KodeChoice Text Format*/
.kc-title-l {
  font-weight: lighter;
}
.kc-h1 {
  font-size: clamp(1rem, 10vw + 1rem, 5rem);
  line-height: 1;
}
/**/
/**/
/* Media query for (mobile devices, 600px  width and down) */
@media only screen and (max-width: 600px) {
  .kc-mobile-hidden {
    display: none;
  }
  /*KodeChoice Grid Container*/
  .kc-grid-container {
    display: block;
  }
  /*KodeChoice Responsive Text Size*/
  .kc-responsive-text {
    font-size: 15px;
  }
  .kc-flex-container {
    display: block;
  }
}

/* Media query for (portrait mode tablets and large phones, 600px width and up) */
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .kc-mobile-hidden {
    display: none;
  }
  /*KodeChoice Grid Container*/
  .kc-grid-container {
    display: block;
  }
  /*KodeChoice Responsive Text Size*/
  .kc-responsive-text {
    font-size: 16px;
  }
  .kc-flex-container {
    display: block;
  }
}

/* Media query for (landscape mode tablets, 768px width and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kc-mobile-hidden {
    display: none;
  }
  /*KodeChoice Grid Container*/
  .kc-grid-container {
    display: block;
  }
  /*KodeChoice List Flex Gap*/
  .kc-flex-gap-xxs {
    gap: 10px;
  }
  .kc-flex-gap-xs {
    gap: 20px;
  }
  .kc-flex-gap-s {
    gap: 25px;
  }
  .kc-flex-gap-xxm {
    gap: 30px;
  }
  .kc-flex-gap-xm {
    gap: 35px;
  }
  .kc-flex-gap-l {
    gap: 40px;
  }
  .kc-flex-gap-xl {
    gap: 45px;
  }
  .kc-flex-gap-xxl {
    gap: 50px;
  }
  /*KodeChoice Responsive Text Size*/
  .kc-responsive-text {
    font-size: 17px;
  }
}

/* Media query for (laptops/desktops, 992px width and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kc-desktop-hidden {
    display: none;
  }
  /*KodeChoice Grid Container*/
  .kc-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  /*KodeChoice List Flex Gap*/
  .kc-flex-gap-xxs {
    gap: 10px;
  }
  .kc-flex-gap-xs {
    gap: 20px;
  }
  .kc-flex-gap-s {
    gap: 30px;
  }
  .kc-flex-gap-xxm {
    gap: 40px;
  }
  .kc-flex-gap-xm {
    gap: 50px;
  }
  .kc-flex-gap-l {
    gap: 60px;
  }
  .kc-flex-gap-xl {
    gap: 70px;
  }
  .kc-flex-gap-xxl {
    gap: 80px;
  }
  /*KodeChoice Responsive Text Size*/
  .kc-responsive-text {
    font-size: 18px;
  }
}

/* Media query for (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .kc-desktop-hidden {
    display: none;
  }
  /*KodeChoice Grid Container*/
  .kc-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  /*KodeChoice List Flex Gap*/
  .kc-flex-gap-xxs {
    gap: 12px;
  }
  .kc-flex-gap-xs {
    gap: 22px;
  }
  .kc-flex-gap-s {
    gap: 32px;
  }
  .kc-flex-gap-xxm {
    gap: 42px;
  }
  .kc-flex-gap-xm {
    gap: 52px;
  }
  .kc-flex-gap-l {
    gap: 62px;
  }
  .kc-flex-gap-xl {
    gap: 72px;
  }
  .kc-flex-gap-xxl {
    gap: 82px;
  }
  /*KodeChoice Responsive Text Size*/
  .kc-responsive-text {
    font-size: 19px;
  }
}
/*Media query responsiveness possible thanks to W3school.*/
